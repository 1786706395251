<app-jumbotron>
  <span title> Who We Are </span>
</app-jumbotron>

<div class="wrapper">
  <div class="top">
    <img class="photo" src="../../../assets/images/sam.jpg" alt="" />

    <div class="lead">
      <h3>About The Company</h3>
      <p *ngFor="let p of hrams">{{ p }}</p>
    </div>

    <div class="contact" content>
      <span itemprop="telephone">
        Cell / Text - <a href="tel:+1719-339-2700"> 719-339-2700 </a>
      </span>
      <span itemprop="email">
        Email -
        <a href="mailto:samsargent@hrams.com">samsargent@hrams.com</a></span
      >
      <span>
        <img src="../../../assets/images/vcard.png" alt="" />
        <a href="../../../assets/vcard/Sam Sargent.vcf">Download</a></span
      >
      <span>
        <img src="../../../assets/images/linkedin.png" alt="" />
        <a href="https://www.linkedin.com/in/hrams/">LinkedIn </a>
      </span>
    </div>
  </div>

  <app-card>
    <div heading>
      <h1>N. E. Sam Sargent</h1>
      <h2>Founder</h2>
    </div>

    <div content>
      <p *ngFor="let p of bio">{{ p }}</p>
    </div>
  </app-card>
</div>
