<app-jumbotron [texture]="true" [big]="true">
  <div class="heading" center>
    <div class="title">
      Human<br />
      Resource<br />
      Asset<br />
      Management<br />
      Systems, Ltd.
    </div>
    <div class="subtitle">
      <span>
        Business problem-solving through <br />
        creative decision processing...
      </span>
    </div>
  </div>
</app-jumbotron>

<div class="wrap">
  <h1>Who We Are</h1>

  <div class="who">
    <span>{{ hrams[0] }}</span>

    <span>{{ hrams[1] }}</span>
  </div>
  <h1>What We Do</h1>

  <div class="services">
    <app-card>
      <div heading class="picker">
        <div class="options">
          <ng-container *ngFor="let service of services; let i = index">
            <div *ngIf="i > 0" class="divider"></div>
            <a (click)="selectOption(i)" class="option" #option>
              <span>{{ service.title }}</span>
            </a>
          </ng-container>
        </div>
      </div>

      <div boundary>
        <div
          *ngIf="selectorWidth"
          class="selector"
          [style.width.px]="selectorWidth"
          [style.left.px]="selectorLeft"
        ></div>
      </div>

      <div content class="details">
        <div class="headings">
          <h2 class="selected-title">{{ selected.title }}</h2>
          <h3 class="selected-subtitle" *ngIf="selected.subtitle">
            {{ selected.subtitle }}
          </h3>
        </div>

        <div class="info">
          <div class="features">
            <span *ngFor="let feature of selected.features">{{ feature }}</span>
          </div>
          <div class="cta">
            <div class="summary">
              {{ selected.summaryHead }}
            </div>
            <button [routerLink]="selected.slug">more info &rarr;</button>
          </div>
        </div>
      </div>
    </app-card>
  </div>
  <!-- 
  <h1>Who We Work With</h1>

  <div class="clients">
    <span>
      Our clients are large and small organizations that have been recognized
      for their achievements by Inc. Magazine's Top 500, Fortune 500, Forbes
      Best 200 small companies in America, and Upside Magazine's Hot 100 private
      companies.
    </span>

    <div class="categories">
      <button routerLink="clients" *ngFor="let bucket of clients">
        {{ bucket.name }}
      </button>
    </div>
  </div>
</div> -->
</div>
