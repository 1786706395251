import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import s from '../../../assets/content/services';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  constructor(private route: Router, private scroller: ViewportScroller) {}

  public services = s;

  public open = false;

  openTray() {
    this.open = !this.open;
  }

  scrollToFooter() {
    this.scroller.scrollToAnchor('footer');
  }

  ngOnInit(): void {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.open = false;
      }
    });
  }
}
