export interface Bucket {
  name: string;
  wide?: boolean,
  clients: string[];
}

export default [
  {
    name: 'Government and Non-Government Agencies (NGOs)  City, State, National and International',
    wide: true,
    clients: [
      // 'California Casualty Insurance Company',
      // 'CareerLab',
      // 'Carousel Dinner Theater',
      // 'Christopher and Associates',
      // 'City of Colorado Springs',
      // 'Clear Creek School District No. RE1',
      // 'Colorado Springs Chamber of Commerce',
      // 'Colorado Springs Utilities',
      // 'Community Partnership for Child Development',
      // 'Cook Communications Ministries',
      // 'Corporate Workforce & Economic Development Credit Union of Denver',
      // 'Denver Postal Credit Union',
      // 'DVD Copy Control Association',
    ],
  },
  {
    name: 'Energy',
    clients: [
      // 'Adams County Government',
      // 'AIMCO Real Estate Investment Trust',
      // 'American Telecasting, Inc.',
      // 'American Teleconferencing, Inc.',
      // 'Analytical Surveys, Inc.',
      // 'Associated Collection Agencies',
      // 'Better Business Bureau of the Pikes Peak Region',
      // 'Blue Mountain Community College',
      // 'Byerly & Company, Inc.',
    ],
  },
  {
    name: 'Healthcare, Life Sciences and Medical Device',
    clients: [
      // 'Estee Lauder Cosmetics',
      // 'Fairmont Hotels',
      // 'Focus Corporation',
      // 'Forker & Associates',
      // 'Fourth Judicial District, Office of the District Attorney',
      // 'Galileo International',
      // 'Gates Rubber Company',
      // 'Holland & Hart',
      // 'Human Resources and Management Solutions',
      // 'Jefferson County Mediation Services',
      // 'Joslins Department Stores',
      // '',
      // 'Lockheed Martin',
      // 'Marathon Oil',
      // 'Matrixx Marketing',
    ],
  },
  {
    name: 'Goverment Contractors',
    clients: [
      // 'Maryland Insurance Group',
      // 'Memorial Hospital',
      // 'Minco Manufacturing',
      // 'Mincom',
      // 'Modis Technology',
      // 'Most Manufacturing',
      // 'Mountain Medical Affiliates, Inc.',
      // 'New Era of Networks (NEON) Software',
      // 'The Plaza Hotel',
      // 'Peoples National Bank',
      // 'Performance Resources Consulting',
      // 'Principal Financial Group',
      // 'Pueblo Housing Authority',
      // 'Queen Brown Management Consultants',
      // 'Regis University',
    ],
  },
  {
    name: 'Sports, Entertainment and Hospitality',
    clients: [
      // 'SCI Systems, Inc.',
      // 'Satellite Hotel',
      // "Shepard's McGraw Hill",
      // 'Sloans Lake Managed Care',
      // 'SKLD',
      // 'Southern Colorado Clinic',
      // 'Streffco',
      // 'Teletech',
      // 'Toshiba',
      // 'Unidata',
    ],
  },
  {
    name: 'Family Owned & Family Office',
    clients: [
      // 'United Track Racing',
      // 'University of Colorado',
      // 'USA Gymnastics',
      // 'USA Hockey',
      // 'Western Forge',
      // 'Westin Hotels & Resorts',
      // 'Xelco',
      // 'Zurich Insurance Co.',
    ],
  },
  {name: 'Manufacturing'}, 
  {name: "Technology"}, 
  {name: "Nonprofits, Foundations and Philanthropy"},
  {name: "Strategic, Expansion, Mergers, Acquisitions and Downsizing planning", wide: true}, 
  {name: "Organizational Design and Human Capital Management", wide: true}, 
  {name: 'Financial and Legal sectors'}, 
  {name: "Executive Business Coaching"},
  {name: 'Recruiting and Retained Search'}, 
] as Bucket[];
