<app-jumbotron>
  <h1 title>Who We Work With</h1>
</app-jumbotron>

<div class="wrap">
  <p>
    Our clients are large and small organizations that have been recognized for
    their achievements by
    <span class="blue">Inc. Magazine's Top 500, Fortune 500,</span> &
    <span class="blue"> Forbes Best 200 small companies in America</span>
  </p>

  <div class="categories">
    <app-card
      *ngFor="let bucket of clients"
      class="card"
      [class.wide]="bucket.wide"
    >
      <h2 heading>{{ bucket.name }}</h2>
      <div content class="list">
        <span *ngFor="let c of bucket.clients">{{ c }}</span>
      </div>
    </app-card>
  </div>
</div>
