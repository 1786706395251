import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';

import s, { Service } from '../../../assets/content/services';
import hrams from '../../../assets/content/hrams';
import clients from '../../../assets/content/clients';
import { ClientsComponent } from '../clients/clients.component';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, AfterViewInit {
  constructor(private changes: ChangeDetectorRef) {}

  public services: Service[] = s;
  public clients = clients;
  public hrams = hrams;

  @ViewChildren('option')
  options: QueryList<ElementRef>;

  private selectedIndex = 0;

  get selectorWidth() {
    return (
      this.options
        ?.toArray()
        .slice(this.selectedIndex, this.selectedIndex + 1)
        .pop().nativeElement.clientWidth +
      (this.selectedIndex === 0 ||
      this.selectedIndex === this.services.length - 1
        ? 32
        : 0)
    );
  }

  get selectorLeft() {
    return (
      this.options
        ?.toArray()
        .reduce(
          (left, option, index) =>
            index < this.selectedIndex
              ? left + option.nativeElement.clientWidth
              : left,
          0
        ) +
      Math.max(this.selectedIndex - 1, 0) +
      (!!this.selectedIndex ? 1 : 0) * 32
    );
  }

  get selected() {
    return this.services[this.selectedIndex];
  }

  selectOption(index: number) {
    this.selectedIndex = index;
  }

  incrementIndex() {
    this.selectedIndex = (this.selectedIndex + 1) % this.services.length;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.changes.detectChanges();
  }
}
