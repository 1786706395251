export default [
'Sam Sargent, founder of Human Resource Asset Management Systems, Ltd., in 1994 is a Human Resource professional with more than 20 years of experience helping businesses deal more effectively with their employees. His background comprises business development, strategic planning, operational planning, and more. He has worked with public and private companies, small and large enterprises, government entities, and not-for-profit organizations. His assignments have taken him from Colorado to both coasts as well as London, Paris, Bucharest, Dubai, Kabul, and Kuala Lumpur. His clients have been recognized by Inc. Magazine Top 500, Fortune 500, Forbes Best 200 Small Companies in America, Deloitte & Touche Technology Fast 50, Upside Magazine Hot 100 Private Companies in America, and Colorado Business Magazine Colorado Top 250 Public and Private Companies.',

'He has developed many far-reaching programs that are still in use today, including Compensation and Benefit systems for exempt and non-exempt employees, and has acted in a fiduciary capacity as plan administrator for qualified and nonqualified benefit plans; Federal, state and local compliance programs; Human Resource planning that comprises staffing-level needs assessment, recruitment, selection, employee evaluation, training, conflict resolution, termination, and outplacement. He has served as Affirmative Action Officer and program manager for Federal DOT drug testing programs, additionally; he has successfully defended employer cases against complaints and or litigation of sexual harassment, wrongful discharge, national origin suits, and age discrimination. He provides expert witness analysis and testimony in cases involving human resource issues.',

'Sam’s achievements include the creation and implementation of a compensation system for a multi-national group of scientists engaged in worldwide and deep-space atmospheric research and analysis; management and administration of a non-qualified multi-employer welfare account trust covering 54 employers and more than 10,000 employees. His Human Resource planning experience includes plant start-ups and shutdowns in the US and Canada; he has implemented full cross-training programs for multi-plant employees across seven distinctly defined disciplines and skillsets. Within the framework of his HR skills, Sam developed both short-term and long-term corporate strategies that link directly to the tactical implementation of the business plan.',

'He has conducted successful mediations and arbitration for El Paso County Small Claims Court, the Neighborhood Justice Center, and the Better Business Bureau (BBB). He received the National BBB Council award of Arbitrator of the year for 1998. He has delivered training sessions and presentations to individuals at all levels, small groups, stockholders and investors, executive staff and officers, boards of directors, and national governing bodies. He has been an adjunct faculty member for both graduate and undergraduate classes for the University Of Colorado School of Business. Sam teaches the SPHR and PHR certification courses for Regis University.  He has contributed or written articles on a variety of human resource issues for Colorado Business Magazine, Colorado Springs Business Journal, Pueblo Business Journal, The Gazette, and has appeared on CBS Channel 11, and nationally syndicated Knowledge TV’s, You.INC.',

"His interest and skill in officiating men’s gymnastics have taken him from local youth-group competitions to world-class events across the United States, Canada, and behind the Iron Curtain. He volunteers his time as a court-appointed special advocate (CASA) representing the best interest of abused and neglected children.",

"Sam’s commitment to the profession is involved and wide-ranging. He is a founding officer and past president of the following organizations; Colorado Springs Society for Human Resource Management (CSSHRM); the Colorado Chapter of the International Society of Certified Employee Benefit Specialists (ISCEBS); and the Rocky Mountain Chapter of the American Compensation Association (RMCA). He is presently the Southern District Director of the SHRM Colorado State Council, Society for Human Resource Management and is the immediate Past President for the Rocky Mountain Compensation Association (RMCA) ",

"He holds a Bachelor of Science degree in Molecular, Cellular, Developmental Biology, and a Master of Business Administration with an emphasis in Human Resources from the University of Colorado. He holds the Society for Human Resource Management designation of Senior Professional Human Resource (SPHR); the American Compensation Association designation of Certified Compensation Professional (CCP); the International Society of Certified Employee Benefit Specialist designation of Certified Employee Benefit Specialist (CEBS) from the Wharton School, University of Pennsylvania; an Associate Degree in Risk Management (ARM) from the Insurance Institute of America, and the Certified Property and Casualty Underwriter (CPCU) designation from the American Institute for Chartered Property Casualty Underwriters."];
