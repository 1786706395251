<nav>
  <button routerLink="/" class="logo">
    Human Resource Asset Management Systems, Ltd.
  </button>
  <span class="spacer"></span>
  <button (click)="openTray()">What We Do</button>
  <button routerLink="/about">Who we are</button>
  <button routerLink="/clients">Who We Work With</button>

  <span class="separator"></span>
  <button (click)="scrollToFooter()" class="inverted">Contact Us</button>
</nav>

<div class="footprint"></div>
<div class="second" [class.open]="open">
  <div class="items">
    <a *ngFor="let ss of services" [routerLink]="'/' + ss.slug">{{
      ss.title
    }}</a>
  </div>
</div>
