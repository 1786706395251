import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import s, { Service } from '../../../assets/content/services';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent implements OnInit {
  constructor(private route: ActivatedRoute) {
    this.route.url.subscribe((url) => {
      this.service = s.find((ss) => ss.slug === url[0].path);
    });
  }

  services: Service[] = s;

  service: Service;

  ngOnInit(): void {}
}
