<footer id="footer">
  <div class="logo col">
    <span>Human</span>
    <span>Resource</span>
    <span>Asset</span>
    <span>Management</span>
    <span>Systems, Ltd.</span>
  </div>
  <div class="spacer"></div>

  <div class="contact col">
    <span itemprop="telephone">
      Cell / Text - <a href="tel:+1719-339-2700"> 719-339-2700 </a>
    </span>
    <span itemprop="email">
      Email -
      <a href="mailto:samsargent@hrams.com">samsargent@hrams.com</a></span
    >

    <div class="address col">
      <span>PO Box 3446</span>
      <span>Monument CO, 80132</span>
    </div>
    <div class="links">
      <span>
        <img src="../../../assets/images/vcard.png" alt="" />
        <a href="../../../assets/vcard/Sam Sargent.vcf">Download</a></span
      >
      <span>
        <img src="../../../assets/images/linkedin.png" alt="" />
        <a href="https://www.linkedin.com/in/hrams/">LinkedIn </a>
      </span>
    </div>
  </div>
</footer>
