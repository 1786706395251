import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss'],
})
export class JumbotronComponent implements OnInit {
  constructor() {}

  @Input()
  texture = false;

  @Input()
  big = false;

  ngOnInit(): void {}
}
