<div [class.big]="big" class="jumbotron">
  <div class="texture"></div>
  <div class="center subtitle">
    <ng-content select="[center]"></ng-content>
  </div>
  <div class="text">
    <span class="title"><ng-content select="[title]"></ng-content></span>
    <span class="subtitle"><ng-content select="[subtitle]"></ng-content></span>
  </div>
</div>
