export default [
  // TOTAL COMPENSATION
  {
    title: 'Total Compensation Systems',
    slug: 'compensation-systems',
    features: [
      'Make your compensation system attract the best for your company.',
      'Pay systems — more expensive than ever before. We can help you stay productive and competitive.',
      'Reward your peak performers with systems that match achievement to compensation.',
      'Benefit systems — more complex than ever before. We can match a benefit system that matches your strategies and objectives.',
    ],
    summaryHead:
      'Total Compensation comprises accurate, timely staging of your overall compensation plan',
    summaryRest:
      ', from executive-plan incentives to exempt, non-exempt salaried systems through to hourly rates for part-time or casual employees, or independent contractors. Compensation continues to evolve rapidly in the midst of corporate restructuring and the emergence of diverse staffing systems. Corporate downsizing and outsourcing — coupled with an emerging part-time / temporary labor pool — is creating a whole new approach to compensation. HRAMS understands this compensation evolution, and can tailor your total compensation program to your specific needs and requirements, and to develop a compensation policy that reinforces your company’s strategies in the marketplace. ',

    points: [
      {
        title: 'Total Direct Compensation and Benefit Systems',
        content:
          'The HRAMS Total Compensation approach enhances the internal effectiveness of your human resource programs. Analyzing, designing, and implementing effective compensation and benefit systems for both exempt and non-exempt employees is critical to the modern business’s economic survival. We analyze your organization and offer you a wide range of selections and choices for every part of your total compensation package.',
      },
      {
        title: 'Managing Compensation',
        content:
          'Compensation is strategic in nature, comprising compensation philosophy, pay structures, incentive plans, compensation plan administration, and accurate, timely employee reward and communication. We look at the entire program, from a big-picture tactical viewpoint and from a detail-oriented implementation and administration approach.',
      },
      {
        title:
          'Government Compliance Through Accurate Job Description and Evaluation',
        content:
          'We identify a methodology for analyzing jobs and job descriptions within the framework of your total organization. Many companies cannot objectively evaluate the internal and external worth of a specific job: we can help. HRAMS can redesign positions and job descriptions to fit even the most rapidly changing environments, as well as creating audit trails and keeping your organization in government compliance.',
      },
      {
        title: 'Direct Compensation and Variable Pay Plans',
        content:
          'Base pay? Variable pay? Incentive pay? Scalable inventive programs? Any base- pay program has to begin by being cost-effective for the organization, and any incentive- pay program must equitably compensate personnel appropriately across all job levels. We’re experts in defining and implementing programs that work for your company.',
      },
      {
        title: 'Benefit Systems',
        content:
          'HRAMS analyzes your existing benefit systems to determine if they meet your strategic goals in attracting, rewarding, and retaining your staff. This analysis helps you adapt your benefit systems to streamline expenses, to complement your direct compensation systems, and to match your systems to the most cost-effective short- and long-term cost/benefit analyses.',
      },
    ],
  },
  // TRAINING AND DEVELOPMENT
  {
    title: 'Training & Development',
    slug: 'training',
    features: [
      'Corporate change is more rapid, more pervasive than ever. HRAMS helps keep current and productive',
      'Developing, managing and administering training is a real resource drain. HRAMS manages your training from start to finish.',
      'There are a lot of training programs out there - many are mediocre. HRAMS delivers results - and real value for every training dollar.',
      'Many companies offer generic training. HRAMS develops comprehensive cross-training programs that reinforce your corporate needs and objectives.',
    ],
    points: [
      {
        title: 'Training Needs Analysis',
        content:
          'What kind of training does your company need? We can design a comprehensive program that fits your business and your budget. ',
      },
      {
        title: 'Learning Principles and the Organization',
        content:
          'Adult learning is different, and is emerging as an important tool for the modern American company. We show you how to work with adult learning characteristics and modify behavior to shorten learning curves and make sure everyone learns effectively and quickly. ',
      },
      {
        title: 'Designing the Best Training',
        content:
          'We develop training plans that help your employees achieve and exceed expectations, and we keep the training interesting and up-to-date for your business.',
      },
      {
        title: 'Training Within Your Organization',
        content:
          ' We tailor training programs to fit your business plan. HRAMS offers both strategic and tactical training to streamline your company to today’s rapidly changing business climate. ',
      },
      {
        title: 'Selecting the Best Training for Your Organization',
        content:
          ' HRAMS is committed to delivering the training you need, including on-the-job training, college coursework, off-site training, vendor selection, source selection (college, graduate school, independent institution), or any appropriate combination.',
      },
      {
        title: 'Delivering the Training',
        content:
          ' Training methods, instructors, curricula, training materials, equipment, and facilities are all important elements of an effective program. We can help you select and manage what’s right for you. ',
      },
      {
        title: 'Evaluating, Analyzing, and Improving the Training',
        content:
          ' Training programs must be fluid — changing with technology, business climates, and more. We never stop evaluating our programs; we work hard to keep these programs modern, meaningful, and effective. ',
      },
    ],
  },
  // CONFLICT RESOLUTION
  {
    title: 'Conflict Resolution',
    subtitle: 'Facilitation, Mediation and Arbitration',
    slug: 'conflict-resolution',
    features: [
      'Resolve manage and staff conflicts to maintain morale and improve productivity.',
      'Effective, skilled mediation resolves employee conflicts and saves you money.',
      'Arbitration is emerging to resolve conflicts without litigation. We’re skilled arbitrators.',
      'HRAMS meeting facilitation helps management and staff focus on business goals and targets.',
    ],
    summaryHead:
      'In today’s high-pressure business environment, conflict resolution is an essential ingredient in any effective human resource policy mix.',
    summaryRest:
      'HRAMS offers a variety of approaches and methodologies to conflict resolution, all tailored to deal appropriately with a variety of conflicts and improve employee performance, or to accurately document the process prior to termination. Our programs are all designed to quantitatively reduce your corporate legal exposure.',
    points: [
      {
        title: 'Facilitation',
        content:
          'HRAMS can reduce overhead by facilitating employee performance and salary reviews, brainstorming and planning sessions, and more. We have experience and expertise with corporate and business goal-setting and team-building at all levels and for large and small organizations.',
      },
      {
        title: 'Facilitated Negotiation / Mediation',
        content:
          'Whether it’s conflict between employee peers or supervisor and employee, or inter-departmental conflict, HRAMS provides discreet, skilled facilitation and mediation in even the most delicate, complex situations.',
      },
      {
        title: 'Mediation and Arbitration',
        content:
          'Resolving workplace issues is difficult, problematic, and expensive; mediation and arbitration are emerging as the most effective, fairest problem-solving mechanism for today’s workplace. HRAMS provides mediation and arbitration skills and experience for virtually every business situation, including complaints and/or litigation of sexual harassment, wrongful discharge, national origin suits, racial discrimination, age iscrimination, gender discrimination, and more.',
      },
    ],
  },
  {
    title: 'Human Resources Audits',
    subtitle: 'Employee Incident Fact Finding and Investigation',
    slug: 'audits',
    features: [
      'Expert HR analysis prior to merger, aquisition, downsizing or reengineering can save you money and eliminate problems.',
      'HRAMS can help you meet your government reporting requirements--accurately and on time.',
      // tslint:disable-next-line: quotemark
      "If you don't have a profeesional, fulltime, Human Resources organization we can evaluate your processes and procedures.",
      'HRAMS can help you endure conformance with your corporate polocies and procedures.',
    ],
    summaryHead:
      'Your business must navigate successfully in a treacherous structure of legal conformance; legal recourse; Federal, state, and local requirements; and governmental reporting.',
    summaryRest:
      ' We can review your policies, procedures, and practices to help you stay current and in compliance. HRAMS offers expertise and experience with these legal and governmental issues in areas of employment recordkeeping, privacy, wage and hour issues, independent contractor status, Workers Compensation, OSHA, harassment, EEO, discrimination, and termination.',
    points: [
      {
        title: 'Human Resources Audit',
        content:
          ' We can evaluate your strengths and areas of development opportunities through on-site examination of all your Human Resource functionality and process, and prepare a report with detailed analysis, priorities, and improvements.',
      },
      {
        title: 'Corporate Due Diligence',
        content:
          // tslint:disable-next-line: quotemark
          " To help you meet your fiduciary responsibilities in obtaining full and meaningful objective and material disclosure of assets and liabilities in handling employee policies and procedures, HRAMS evaluates past and present practices. We project existing and potential issues to help assess the appropriate value of your company's Human Resource assets. ",
      },
    ],
  },
  // TALENT MANAGEMENT
  {
    title: 'Talent Management',
    slug: 'talent-management',
    features: [
      'Downsizing, outplacement, reengineering: difficult and emotional. We can make it easier to manage.',
      'Experience and skills at competitive rates are rare in today’s market. We help you evaluate and select the best people.',
      'Payroll is your single biggest overhead cost. We can help you get the right skills and experience at the right price.',
      // tslint:disable-next-line: max-line-length
      'Most companies don’t do any career planning until they need to downsize. We can help you solve problems before they become problems.',
    ],
    points: [
      {
        title: '	Staffing-Level Needs Assessment',

        content:
          'We review and assess your staffing environment, including job analysis, salary costs, and industry comparisons. From this research and analysis, we develop a working profile of the needs and requirements that are unique to your organization.',
      },
      {
        title: 'Recruitment',

        content:
          'Each company’s recruiting strategy is different, flavored for particular, unique needs. We can help you develop a recruiting program that takes advantage of the best internal and external resources while keeping your business competitive and your operating costs down.',
      },
      {
        title: 'Selection',

        content:
          'Every business wants to hire the right person — the first time. We’ve developed a selection toolset to help every manager make the best selection for every position. This toolset includes meaningful application forms, job history search techniques, interviewing methods and mechanisms, screening processes, pre- and post-employment skills testing, drug screening, medical examinations, and more.',
      },
      {
        title: 'Employee Evaluation',
        content:
          'Our programs help guide you through the various levels of employee evaluation, and provide a working template for managers and executives to accelerate and refine the evaluation process, and to make effective decisions on retaining employees, creating developmental plans for employees, or terminating employees.',
      },
      {
        title: 'Career Planning and Development',
        content:
          'We evaluate your HR environment, working with individual needs and the needs of your organization, and design effective, attainable career paths for your employees.',
      },
      {
        title: 'Termination',
        content:
          'HRAMS understands the requirements and processes for employee termination. We can answer your questions about these difficult, emotional management decisions.',
      },
      {
        title: 'Outplacement',
        content:
          'For companies facing the difficult task of downsizing, HRAMS offers outplacement services to help ex-employees make the transition to new opportunities. We help remaining staff members traverse the difficulties of “surviving” the trauma of downsizing, and help those employees re-commit their loyalty to the organization. Our outplacement programs help you plan the transition, manage the transition, and evaluate the transition process. We’re experts in developing programs that make a difference.',
      },
    ],
  },
  // STAFF SELECTION
  {
    title: 'Performance Management',
    slug: 'performance-management',
    features: [
      'Downsizing, outplacement, reengineering: difficult and emotional. We can make it easier to manage.',
      'Experience and skills at competitive rates are rare in today’s market. We help you evaluate and select the best people.',
      'Payroll is your single biggest overhead cost. We can help you get the right skills and experience at the right price.',
      // tslint:disable-next-line: max-line-length
      'Most companies don’t do any career planning until they need to downsize. We can help you solve problems before they become problems.',
    ],
    points: [
      {
        title: '	Staffing-Level Needs Assessment',

        content:
          'We review and assess your staffing environment, including job analysis, salary costs, and industry comparisons. From this research and analysis, we develop a working profile of the needs and requirements that are unique to your organization.',
      },
      {
        title: 'Recruitment',

        content:
          'Each company’s recruiting strategy is different, flavored for particular, unique needs. We can help you develop a recruiting program that takes advantage of the best internal and external resources while keeping your business competitive and your operating costs down.',
      },
      {
        title: 'Selection',

        content:
          'Every business wants to hire the right person — the first time. We’ve developed a selection toolset to help every manager make the best selection for every position. This toolset includes meaningful application forms, job history search techniques, interviewing methods and mechanisms, screening processes, pre- and post-employment skills testing, drug screening, medical examinations, and more.',
      },
      {
        title: 'Employee Evaluation',
        content:
          'Our programs help guide you through the various levels of employee evaluation, and provide a working template for managers and executives to accelerate and refine the evaluation process, and to make effective decisions on retaining employees, creating developmental plans for employees, or terminating employees.',
      },
      {
        title: 'Career Planning and Development',
        content:
          'We evaluate your HR environment, working with individual needs and the needs of your organization, and design effective, attainable career paths for your employees.',
      },
      {
        title: 'Termination',
        content:
          'HRAMS understands the requirements and processes for employee termination. We can answer your questions about these difficult, emotional management decisions.',
      },
      {
        title: 'Outplacement',
        content:
          'For companies facing the difficult task of downsizing, HRAMS offers outplacement services to help ex-employees make the transition to new opportunities. We help remaining staff members traverse the difficulties of “surviving” the trauma of downsizing, and help those employees re-commit their loyalty to the organization. Our outplacement programs help you plan the transition, manage the transition, and evaluate the transition process. We’re experts in developing programs that make a difference.',
      },
    ],
  },
] as Service[];

export interface Service {
  slug: string;
  title: string;
  subtitle?: string;
  summaryHead?: string;
  summaryRest?: string;
  features: string[];
  points: { title: string; content: string }[];
}
