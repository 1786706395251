<app-jumbotron>
  <h1 title>{{ service.title }}</h1>
  <h2 *ngIf="service.subtitle" subtitle>{{ service.subtitle }}</h2>
</app-jumbotron>

<div class="wrap">
  <div>
    <div *ngIf="service.summaryHead" class="summary">
      <p>{{ service.summaryHead }}{{ service.summaryRest }}</p>
    </div>

    <div *ngFor="let point of service.points" class="point">
      <h3>{{ point.title }}</h3>
      <p>{{ point.content }}</p>
    </div>
  </div>
  <div class="offerings">
    <app-card>
      <div heading><h2>Offerings</h2></div>
      <div content class="content">
        <div class="offering" *ngFor="let ss of services">
          <a [routerLink]="'/' + ss.slug">{{ ss.title }}</a>
        </div>
      </div>
    </app-card>
  </div>
</div>
