import { Component, OnInit } from '@angular/core';

import clients from '../../../assets/content/clients';

@Component({
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  constructor() {}

  public clients = clients;

  ngOnInit(): void {}
}
