import { Component, OnInit } from '@angular/core';

import bio from '../../../assets/content/bio';
import hrams from '../../../assets/content/hrams';

@Component({
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  constructor() {}

  public bio = bio;
  public hrams = hrams;

  ngOnInit(): void {}
}
